import React from "react";
import { useEngWalterData } from "lang/en/walter";
import Walter from "sections/Walter/Walter";

const WalterPage = () => {
  const data = useEngWalterData();
  return <Walter data={data} />;
};

export default WalterPage;

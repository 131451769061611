import { useWalterPhotos } from "shared/Hooks/walter/useWalterPhotos";

export const useEngWalterData = () => {
  const photos = useWalterPhotos();
  return {
    metaInfo: {
      title: "Walter",
      ogDescription:
        "If you’re looking for innovative solutions, look no further than Walter. This producer skillfully uses modern technology in striving for perfection and maximum precision. Their solutions guarantee savings while maintaining high production standards.",
      ogImage: photos.logo.childImageSharp.src,
    },
    hero: {
      title: "WALTER",
      subtitle:
        "If you’re looking for innovative solutions, look no further than Walter. This producer skillfully uses modern technology in striving for perfection and maximum precision. Their solutions guarantee savings while maintaining high production standards.",
      bg: photos.hero,
    },
    mainCatalogue: {
      title: "WALTER Catalog",
      subtitle:
        "Holders that fit the needs of every industry: whether it requires greater flexibility, minimized vibration or high cutting force.",
      links: [
        {
          url: "/catalogs/walter/general-catalogue-2016-pl.pdf",
          text: "WALTER Global Catalog",
        },
      ],
      bigImg: true,
      src: photos.img1,
    },
    milling: {
      title: "Milling",
      subtitle:
        "Longer tool life, maximum safety in use, as well as the highest stability and accuracy. Walter milling systems are known for production efficiency and savings while maintaining the most environmentally-friendly standards.",
      links: [
        {
          url: "/catalogs/walter/flyer-conefit-pl.pdf",
          text: "Modular milling system",
        },
        {
          url: "/catalogs/walter/flyer-dynamic-milling-pl.pdf",
          text: "Dynamic Milling",
        },
        {
          url: "/catalogs/walter/flyer-m4000-2016-pl.pdf",
          text: "M4000 cutter system",
        },
      ],
      bigImg: true,
      src: photos.img2,
    },
    materials: {
      title: "Turning",
      subtitle:
        "Walter tools reduce machining time and ensure the highest cutting parameters. These technologies are uniquely capable of ensuring resistance to variable thermal loads during either wet or dry machining and guaranteeing an increase in efficiency.",
      links: [
        {
          url: "/catalogs/walter/flyer-ttg-2016-pl.pdf",
          text: "Modular milling system",
        },
        {
          url:
            "/catalogs/walter/flyer-tts-turning-milling-2011-pl.pdf",
          text: "Dynamic milling",
        },
        {
          url:
            "/catalogs/walter/flyer-perform-line-turning-2019-pl.pdf",
          text: "M4000 cutter system",
        },
        {
          url:
            "/catalogs/walter/flyer-boring-precision-boring-pl.pdf",
          text: "Rough and fine boring",
        },
      ],
      bigImg: true,
      src: photos.img3,
    },
    dc170: {
      title: "DC170 – the drilling icon",
      subtitle:
        "DC170 is a cutting blade with a unique design that ensures the highest stability during processing and significantly better hole quality. It is an icon of a new class in performance – the special orientation of its surfaces reduces vibration to the absolute minimum, increasing safety in the drilling process. DC170 means high efficiency, and also savings.",
      links: [
        {
          url: "/catalogs/walter/flyer-dc170-pl.pdf",
          text: "DC170",
        },
      ],
      bigImg: true,
      src: photos.img4,
    },
    vending: {
      title: "Tool issuing systems",
      subtitle:
        "Walter dispensers are a fully customizable and transparent way to manage your tools. Cabinets are equipped with convenient touch screens and an intuitive interface. They allow for a reduced inventory and production equipment is available round the clock – without any downtime.",
      links: [
        {
          url:
            "/catalogs/walter/flyer-vending-network-line-pl.pdf",
          text: "Tool issuing system: Vending Network Line",
        },
        {
          url:
            "/catalogs/walter/flyer-walter-vending-d-line-pl.pdf",
          text: "Tool issuing system: Walter Vending Line",
        },
      ],
      bigImg: true,
      src: photos.img5,
    },
  };
};
